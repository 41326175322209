import React from 'react'
import { Link } from 'react-router-dom';

const BlogPost = ({ post }) => {
    return (
        <article className="border-b border-gray-200 pb-8">
            <h3 className="text-2xl font-semibold mb-2">
                <Link to={`/article/${post.id}`} className="text-black hover:underline">{post.title}</Link>
            </h3>
            <p className="text-gray-600 mb-2">{post.excerpt}</p>
            <div className="text-sm text-gray-500">
                <span>{post.author}</span> • <time dateTime={post.date}>
                    {new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                </time>
            </div>
        </article>
    )
}

export default BlogPost