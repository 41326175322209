import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { generateSignature } from '../Utils/Helper';
import TableOfContents from '../Components/TableOfContents';
import Footer from '../Components/Footer';

const Post = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headings, setHeadings] = useState([]);
  const { articleId } = useParams();
  const articleRef = useRef(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        setIsLoading(true);
        const payload = {
          "article_id": articleId
        }
        const { signature, timestamp } = generateSignature(payload, 'GET');
        const response = await axios.get(`https://api.openblog.dev/v1/article?article_id=${articleId}`, {
          headers: {
            'x-signature': signature,
            'x-timestamp': timestamp
          }
        });
        setContent(response.data.data.content);
        setTitle(response.data.data.title);
        setError(null);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError('Error loading blog post. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchBlogPost();
  }, [articleId]);

  useEffect(() => {
    if (articleRef.current) {
      const headingElements = articleRef.current.querySelectorAll('h1, h2');
      const extractedHeadings = Array.from(headingElements).map((el, index) => ({
        level: el.tagName.toLowerCase(),
        text: el.textContent,
        id: `heading-${index}`
      }));
      setHeadings(extractedHeadings);

      // Add IDs to the heading elements
      headingElements.forEach((el, index) => {
        el.id = `heading-${index}`;
      });
    }
  }, [content]);

  const createMarkup = (content) => {
    const rawMarkup = marked(content);
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  if (isLoading) return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-black font-bold text-lg'>Loading...</div>
    </div>
  );

  if (error) return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-black font-bold text-lg'>{error}</div>
    </div>
  );

  return (
    <div className="bg-white text-black min-h-screen p-4 sm:p-6 md:p-8">
      <div className="max-w-3xl mx-auto lg:max-w-6xl lg:flex lg:gap-8">
        <div className="lg:flex-grow">
          <nav className="mb-8">
            <Link to="/community" className="text-black hover:underline">← Back to all posts</Link>
          </nav>
          <h1 className='text-black font-bold text-4xl mb-8'>{title}</h1>
          <article
            ref={articleRef}
            id="blog-post"
            className="markdown-body"
            dangerouslySetInnerHTML={createMarkup(content)}
          />
        </div>
        <TableOfContents headings={headings} />
      </div>
      <Footer />
    </div>
  );
};

export default Post;