import React, { useState, useEffect, useContext } from 'react';
import { marked } from 'marked';
import axios from 'axios';
import { toast } from 'react-toastify';
import { generateSignature } from '../Utils/Helper';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../helper/SessionContext';

const Editor = ({ fromProfile = false, blog_id = null }) => {
  const [postTitle, setPostTitle] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [htmlOutput, setHtmlOutput] = useState('');
  const [hideFromCommunity, setHideFromCommunity] = useState(false);
  const { sessionId } = useContext(SessionContext);
  const navigate = useNavigate();

  marked.setOptions({
    gfm: true,
    breaks: true,
  });


  useEffect(() => {
    const updatePreview = () => {
      const html = marked(markdown);
      setHtmlOutput(`<h1>${postTitle}</h1>${html}`);
    };

    updatePreview();
  }, [postTitle, markdown]);

  const handleCopy = () => {
    navigator.clipboard.writeText(htmlOutput).then(() => {
      toast("🔥 HTML copied to clipboard!")
    });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (postTitle !== '' && markdown !== '') {
      const payload = {
        "post_title": postTitle,
        "post_content": markdown,
        "is_private": hideFromCommunity,
        "blog_id": blog_id
      }
      const { data, signature, timestamp } = generateSignature(payload, 'POST');

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/article`,
        headers: {
          'Content-Type': 'application/json',
          'x-signature': signature,
          'x-timestamp': timestamp,
          'Authorization': sessionId
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          //setIsLoading(false)
          const data = response.data
          if (response.status === 201) {
            navigate(`/article/${data.post_id}`);
          }
        })
        .catch((error) => {
          //setIsLoading(false)
          toast(`😢 ${error.response?.data?.message || 'Your request was unsuccessful, Please try again.'}`)
        });
    } else {
      console.log('missing details')
    }
  }

  return (
    <div className="bg-white text-black min-h-screen p-4">
    <div className="mx-auto text-left"> 
      <input
        type="text"
        id="post-title"
        placeholder="Untitled"
        required
        className="w-full text-2xl font-semibold mb-4 p-2 border-b border-gray-200 focus:outline-none focus:border-black"
        value={postTitle}
        onChange={(e) => setPostTitle(e.target.value)}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <textarea
            id="markdown-input"
            className="w-full h-[calc(100vh-250px)] p-4 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-black"
            placeholder="Start writing your post in Markdown..."
            value={markdown}
            required
            onChange={(e) => setMarkdown(e.target.value)}
          ></textarea>
        </div>
        <div>
          <div
            id="html-output"
            className="w-full h-[calc(100vh-250px)] p-4 border rounded-md bg-white shadow-sm markdown-body overflow-auto"
            dangerouslySetInnerHTML={{ __html: htmlOutput }}
          ></div>
        </div>
      </div>
      {fromProfile ?
        <div className="flex items-center">
          <label htmlFor="show-in-community" className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                id="show-in-community"
                className="sr-only"
                checked={hideFromCommunity}
                onChange={() => setHideFromCommunity(!hideFromCommunity)}
              />
              <div className={`w-10 h-6 ${hideFromCommunity ? 'bg-gray-700' : 'bg-gray-300'} rounded-full shadow-inner`}></div>
              <div className={`absolute w-4 h-4 bg-white rounded-full shadow inset-y-1 left-1 transition-transform duration-300 ease-in-out ${hideFromCommunity ? 'transform translate-x-full bg-black' : ''}`}></div>
            </div>
            <div className="ml-3 text-gray-700 font-medium">
              Hide from community
            </div>
          </label>
        </div>
        : ''
      }
      <div className="flex flex-wrap gap-4 mt-6">
        {!fromProfile ?
          <button
            id="copy-btn"
            className="px-4 py-2 bg-white text-black border border-black rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
            onClick={handleCopy}
          >
            Copy HTML
          </button> : ''
        }
        <button
          id="save-btn"
          className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
          onClick={handleSubmit}
        >
          Publish
        </button>
      </div>
    </div>
  </div>
  );
}

export default Editor;
