import React, { useState, useCallback, useContext, useEffect } from 'react'
import ProfileHeader from '../Components/ProfileHeader'
import { useNavigate, Link } from 'react-router-dom';
import { SessionContext } from '../helper/SessionContext';
import Editor from '../Components/Editor';
import Analytics from '../Components/Analytics';
import Modal from '../Components/Modal';
import { Loader2 } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Components/Footer';

import axios from 'axios'

const Profile = () => {
    const [userdata, setUserdata] = useState(null)
    const [blogdata, setBlogdata] = useState([])
    const [analyticsdata, setAnalyticsdata] = useState([])
    const { sessionId, clearSessionId } = useContext(SessionContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editmodalIsOpen, setEditModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [hasFetched, setHasFetched] = useState(false);
    const [blogName, setBlogName] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [selectedBlog, setSelectedBlog] = useState(null);

    // Function to handle changing the selected blog
    const handleChange = (event) => {
        setSelectedBlog(event.target.value);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openEditModal = () => {
        setEditModalIsOpen(true);
    };

    const closeEditModal = () => {
        setEditModalIsOpen(false);
    };

    const handleSave = () => {
        setIsLoading(true)
        if (blogName !== '') {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/blog`,
                headers: {
                    'Authorization': sessionId
                },
                data: {
                    "blog_name": blogName
                }
            };

            axios.request(config)
                .then((response) => {
                    setIsLoading(false)
                    if (response.status === 201) {
                        fetchUser()
                        closeModal()
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log(error);
                    toast(`😢 ${error.response?.data?.message || 'Your request was unsuccessful, Please try again.'}`)
                    if (error.response?.status === 401) {
                        clearSessionId();
                        navigate(`/auth`);
                    }
                });
        } else {
            setIsLoading(false)
        }
    }

    const fetchUser = useCallback(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/me`,
            headers: {
                'Authorization': sessionId
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setBlogdata(data.blog)
                    setSelectedBlog(data.blog[0])
                    setUserdata(data.user_data);
                    setAnalyticsdata(data.analytics)
                    setHasFetched(true);
                }
            })
            .catch((error) => {
                console.log(error);
                toast(`😢 ${error.response?.data?.message || 'Your request was unsuccessful, Please try again.'}`)
                if (error.response?.status === 401) {
                    clearSessionId();
                    navigate(`/auth`);
                }
            });
    }, [sessionId, navigate, clearSessionId]);

    useEffect(() => {
        if (sessionId && !hasFetched) {
            fetchUser()
        }
    }, [sessionId, hasFetched, fetchUser])

    if (!hasFetched) return (
        <div className='flex items-center justify-center h-screen'>
            <div className='text-black font-bold text-lg'>Loading...</div>
            <ToastContainer />
        </div>
    );
    return (
        <div className="bg-white text-gray-900">
            <ProfileHeader content={userdata} />
            {(blogdata.length === 0) ?
                <div className='flex justify-center items-center h-screen text-center px-14'>
                    <div className='container'>
                        <i class="fa-regular fa-pen-to-square mb-3"></i>
                        <p className='mb-3 font-semibold text-lg'>Create your first blog.</p>
                        <p className='mb-5 font-medium text-sm text-gray-400'>This is where all your blog posts will live. You can always update info about it later.</p>
                        <button className='text-gray-600 px-4 py-3 rounded font-medium text-sm ring-2 ring-gray-300'
                            onClick={openModal}
                        >
                            Create your first blog
                        </button>
                    </div>
                </div> :
                <div className="container mx-auto px-4 py-8">
                    <div className="flex flex-col space-y-2">
                        <Analytics analyticsData={analyticsdata} />
                        <div className='flex justify-end mr-4 space-x-2'>
                            <div className="flex items-center space-x-2">
                                <p className='text-gray-400 text-sm'>Active Blog:</p>
                                <select
                                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200 w-48"
                                    value={selectedBlog}
                                    onChange={handleChange}
                                >
                                    {blogdata.map((blog, index) => <option key={index} value={blog}>{blog.blog_name}</option>)}
                                </select>
                            </div>
                            <Link to={selectedBlog.blog_url}
                                className="px-4 py-2 text-white bg-black text-sm rounded-md ring-gray-300 font-medium  ring-1 focus:outline-none focus:ring-2 focus:ring-gray-600"
                                target={"_blank"}
                                rel="noopener noreferrer"
                            >Preview <i class="ml-2 fa-solid fa-link text-white"></i>
                            </Link>
                            <button 
                            className="inline-flex items-center justify-center w-10 h-10 rounded-md ring-1 ring-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-600"
                            onClick={openEditModal}
                            >
                            <i className="fas fa-gear text-gray-500 text-lg"></i>
                            </button>
                        </div>
                        <h2 className='flex justify-start ml-4 text-xl font-bold ' >New Post <i class="fa-solid fa-pen ml-3"></i> </h2>
                        <Editor fromProfile={true} blog_id={selectedBlog.blog_id} />
                    </div>
                </div>
            }
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Create a blog."
            >
                <div className='flex justify-center items-center'>
                    <div className='container max-w-sm mt-5'>
                        <p className='font-bold text-gray-500 mb-3'>Blog Name</p>
                        <input
                            type="email"
                            placeholder="My Super Awesome Blog"
                            value={blogName}
                            required
                            onChange={(e) => setBlogName(e.target.value)}
                            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleSave}
                            className="w-full px-4 py-[13px] mb-4 bg-black text-white text-sm rounded justify-center flex"
                            disable={isLoading}
                        >
                            {isLoading ?
                                <Loader2 className="w-5 h-5 animate-spin text-center" />
                                :
                                'Save'
                            }

                        </button>
                    </div>
                </div>

            </Modal>
            <Modal
                isOpen={editmodalIsOpen}
                onRequestClose={closeEditModal}
                contentLabel="Edit Blog."
            >
                <div className='flex justify-center items-center'>
                    <div className='container max-w-sm mt-5'>
                        <p className='font-bold text-gray-500 mb-3'>Blog Name</p>
                        <input
                            type="email"
                            placeholder="My Super Awesome Blog"
                            value={selectedBlog.blog_name}
                            required
                            onChange={(e) => setBlogName(e.target.value)}
                            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleSave}
                            className="w-full px-4 py-[13px] mb-4 bg-black text-white text-sm rounded justify-center flex"
                            disable={isLoading}
                        >
                            {isLoading ?
                                <Loader2 className="w-5 h-5 animate-spin text-center" />
                                :
                                'Save'
                            }

                        </button>
                    </div>
                </div>

            </Modal>
            <ToastContainer />
            <Footer />
        </div>
    )
}

export default Profile