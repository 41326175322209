import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { generateSignature } from '../Utils/Helper';
import BlogPost from '../Components/BlogPost'
import axios from 'axios';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const Community = () => {
  const [blogPosts, setblogPosts] = useState([])
  const [featuredPost, setFeaturedPost] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        setIsLoading(true);
        const payload = {}
        const { signature, timestamp } = generateSignature(payload, 'GET');
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/articles`, {
          headers: {
            'x-signature': signature,
            'x-timestamp': timestamp
          }
        });
        setblogPosts(response.data.articles)
        setFeaturedPost(response.data.featured_post)
        setError(null);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError('Error loading blog post. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchBlogPost();
  }, []);

  if (isLoading) return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-black font-bold text-lg'>Loading...</div>
    </div>
  );

  if (error) return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-black font-bold text-lg'>{error}</div>
    </div>
  );
  return (
    <div className="relative min-h-screen">
      <Header />
      <div className="bg-white text-black min-h-screen">
        <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6">Featured Post</h2>
            <div className="bg-gray-100 p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-2">
                <Link to={`/article/${featuredPost.id}`} className="text-black hover:underline">{featuredPost.title}</Link>
              </h3>
              <p className="text-gray-600 mb-4">{featuredPost.excerpt}</p>
              <div className="text-sm text-gray-500">
                <span>By {featuredPost.author}</span> • <time dateTime={featuredPost.date}>{featuredPost.date}</time>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-3xl font-semibold mb-8">Recent Posts</h2>
            <div className="space-y-8">
              {blogPosts.map(post => <BlogPost key={post.id} post={post} />)}
            </div>
          </section>

        </main>
      </div>
      <Link 
        to="/" 
        className="fixed bottom-8 right-8 bg-black text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
      >
        New Post
      </Link>
      <Footer />
    </div>
  )
}

export default Community