import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Editor from '../Components/Editor';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const Home = () => {
    return (
        <div className="bg-white text-gray-900">
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>OpenBlog - Blogging for Developers</title>
            </Helmet>
            <Header />
            <main className="container mx-auto px-4 py-20 text-center">
                <h1 className="text-5xl md:text-6xl font-bold mb-6" style={{ lineHeight: '1.5' }}>
                    Simple Blogging for<br />Developers 💻
                </h1>

                <p className="text-lg text-gray-600 mb-10 max-w-2xl mx-auto">
                    Instantly create and publish blog posts using markdown, no account needed.
                </p>
                <Editor />
                <Footer />
            </main>
            <ToastContainer />
        </div>
    );
}

export default Home;
