import React from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="container mx-auto px-4 py-6 flex justify-between items-center">
      <Link to="/">
      <div className="text-2xl font-extrabold">OpenBlog</div>
      </Link>
    <nav className="md:flex space-x-6">
      <Link to="/community" className="text-gray-600 hover:text-gray-900">Comunity</Link>
      <Link to="/profile" className="text-gray-600 hover:text-gray-900">Account</Link>
    </nav>
  </header>
  )
}

export default Header