// Helper.js
import CryptoJS from 'crypto-js';

const secret = process.env.REACT_APP_SECRET_KEY;

export function generateSignature(payload = '', method = 'POST') {
    const timestamp = Math.floor(Date.now() / 1000);
    const data = method === 'GET' ? payload : { payload, timestamp };
    const dataString = JSON.stringify(data);
    const signature = CryptoJS.HmacSHA256(dataString, secret).toString();
    return { data: dataString, signature, timestamp };
  }
  
