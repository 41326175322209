import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer class="bg-white text-black mt-auto">
            <div class="container mx-auto py-8 px-4">
                <div class="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
                <Link to="/article/03ac23d7-be7d-48f9-89c1-077b84dd8dcf" 
                    class="hover:text-gray-700 text-gray-500"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    >Privacy</Link>

                    <Link to="/article/ad4328e1-9c91-4aa5-93e7-423b3adc544c" 
                    class="hover:text-gray-700 text-gray-500"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    >Terms of Service</Link>

                    <Link to="https://twitter.com/kingjulian_i"
                        class="hover:text-gray-700 text-gray-500"
                        target={"_blank"}
                        rel="noopener noreferrer">
                        <i class="fab fa-twitter"></i> Twitter
                    </Link>
                </div>

            </div>
        </footer>
    )
}

export default Footer