import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';


const Authentication = () => {
    const [email, setEmail] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const handleLogin = async () => {
        setIsLoading(true)
        if (email) {
            let data = JSON.stringify({
                "email_address": email
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/auth`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setIsLoading(false)
                    setEmail('')
                    const data = response.data
                    if (response.status === 200) {
                        console.log('auth success')
                        toast(`🥳 ${data.message}`)
                    } else {
                        toast(`😢 ${data.message || 'Your request was unsuccessful, Please try again.'}`)
                    }
                })
                .catch((error) => {
                    setEmail('')
                    setIsLoading(false)
                    console.log(error);
                    toast(`😢 ${error.data?.message || 'Your request was unsuccessful, Please try again.'}`)
                });
        } else {
            setIsLoading(false)
        }
    };
    return (
        <div className='h-screen'>
            <nav className="m-8">
                <Link to="/" className="text-black hover:underline">← Back to homepage</Link>
            </nav>
            <div className="bg-white text-black flex items-center justify-center h-screen">
                <div className="flex w-full max-w-md">
                    <div className="w-full flex flex-col items-center justify-center p-8">
                        <div className="w-full max-w-md">
                            <h2 className="text-2xl font-bold mb-3">Continue to your account</h2>
                            <p className="mb-6">Enter your email below</p>
                            <input
                                type="email"
                                placeholder="name@example.com"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-4 py-2 mb-4 border border-gray-300 rounded text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                onClick={handleLogin}
                                className="w-full px-4 py-[13px] mb-4 bg-black text-white text-sm rounded justify-center flex"
                                disable={isLoading}
                            >
                                {isLoading ?
                                    <Loader2 className="w-5 h-5 animate-spin text-center" />
                                    :
                                    'Continue with Email'
                                }

                            </button>
                            <p className="text-gray-400 text-sm mb-4">If you do not have an account, we will automatically create one for you</p>
                            <p className="text-gray-400 text-sm">
                                By clicking continue, you agree to our{' '}
                                <Link to="/article/ad4328e1-9c91-4aa5-93e7-423b3adc544c"
                                    className="underline"
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                >
                                    Terms of Service
                                </Link>{' '}
                                and{' '}
                                <Link to="/article/03ac23d7-be7d-48f9-89c1-077b84dd8dcf"
                                    className="underline"
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </Link>.
                            </p>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default Authentication;
