import React from 'react';

const AnalyticCard = ({ header, subHeader }) => (
  <div className="bg-white border border-gray-200 rounded-lg p-6">
    <h3 className="text-xl font-bold text-gray-800">{header}</h3>
    <p className="text-sm font-medium text-gray-500 mt-2">{subHeader}</p>
  </div>
);

const Analytics = ({analyticsData}) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {analyticsData.map((data, index) => (
          <AnalyticCard key={index} header={data.header} subHeader={data.subHeader} />
        ))}
      </div>
    </div>
  );
};

export default Analytics;