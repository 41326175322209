import './App.css';
import './index.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './helper/ProtectedRoutes';
import { SessionProvider } from './helper/SessionContext';
import Home from './Pages/Home';
import Community from './Pages/Community';
import Post from './Pages/Post';
import Authentication from './Pages/Authentication';
import Confirm from './Pages/Confirm';
import Profile from './Pages/Profile';
import Blog from './Pages/Blog';

function App() {
  return (
    <Router>
      <SessionProvider>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/community" element={<Community />} />
          <Route path="/article/:articleId" element={<Post />} />
          <Route path="/auth" element={<Authentication />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/blog" element={<Blog />} />
          <Route element={<ProtectedRoute />}>
          <Route path="/profile" element={<Profile />} />
          </Route>
        </Routes>
      </SessionProvider>
    </Router>
  );
}

export default App;
