// ProtectedRoutes.js
import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { SessionContext } from './SessionContext';

const ProtectedRoute = () => {
    const { sessionId } = useContext(SessionContext);

    return sessionId ? <Outlet /> : <Navigate to="/auth" />;
};

export default ProtectedRoute;