import React from 'react';

const TableOfContents = ({ headings }) => {
    return (
        <div className="hidden lg:block lg:w-64 lg:flex-shrink-0">
            <div className="sticky top-20 max-h-[calc(100vh-5rem)] overflow-auto">
                <h2 className="text-lg font-bold mb-2">On This Page</h2>
                <ul className="space-y-2">
                    {headings.map((heading, index) => (
                        <li key={index} className={`${heading.level === 'h2' ? 'ml-4' : ''}`}>

                            <a href={`#${heading.id}`}
                                className="text-gray-700 hover:text-black hover:underline"
                            >
                                {heading.text}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TableOfContents;