import React, { createContext, useState } from 'react';

const SessionContext = createContext();

const SessionProvider = ({ children }) => {
    const [sessionId, setSessionId] = useState(
        localStorage.getItem('sessionId') || null
    );

    const storeSessionId = (id) => {
        setSessionId(id);
        localStorage.setItem('sessionId', id);
    };

    const clearSessionId = () => {
        setSessionId(null);
        localStorage.removeItem('sessionId');
    };

    return (
        <SessionContext.Provider value={{ sessionId, storeSessionId, clearSessionId }}>
            {children}
        </SessionContext.Provider>
    );
};

export { SessionContext, SessionProvider };