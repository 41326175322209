import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SessionContext } from '../helper/SessionContext';

const Confirm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { storeSessionId } = useContext(SessionContext);
    const location = useLocation();
    const navigate = useNavigate();

    const confirmSession = useCallback(async () => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');

        if (token) {
            let data = { "token": token };

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/auth/confirm`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            };

            try {
                const response = await axios.request(config);
                const data = response.data;
                setIsLoading(false)
                if (response.status === 200) {
                    console.log('Auth success');
                    const sessionId = data.session.session_id;
                    storeSessionId(sessionId);
                    navigate(`/profile`);
                } 
            } catch (error) {
                console.log(error)
                setIsLoading(false)
                if (error.response) {
                    setError(`${error.response.data?.message || 'Your request was unsuccessful, Please try again.'}`);
                } else {
                    setError('An unexpected error occurred. Please try again.');
                }
            }
        }
    }, [location.search, storeSessionId, navigate]);

    useEffect(() => {
        confirmSession();
    }, [confirmSession]);

    if (isLoading) return (
        <div className='flex items-center justify-center h-screen'>
          <div className='text-black font-bold text-lg'>Loading...</div>
        </div>
      );
    
      if (error) return (
        <div className='flex items-center justify-center h-screen'>
          <div className='text-black font-bold text-lg'>{error}</div>
        </div>
      );
  return (
    <div className='flex items-center justify-center h-screen'>
    <div className='text-black font-bold text-lg'></div>
  </div>
  )
}

export default Confirm